import React, { Fragment } from "react";
import Layout from "@ui/layout";

import StaticImage from "@components/StaticImage";

import HeaderBackgroundIllustration from "@components/HeaderBackgroundIllustration";

import StyledHomeHeader from "@views/Home/components/HomeHeader/HomeHeader.styled";
import StyledHomeHeaderInner from "@views/Home/components/HomeHeader/components/HomeHeaderInner.styled";
import StyledHomeHeaderMain from "@views/Home/components/HomeHeader/components/HomeHeaderMain.styled";
import StyledHomeHeaderLaptop from "@views/Home/components/HomeHeader/components/HomeHeaderLaptop.styled";

import "../styles/contact.css";
import HomeHeaderBackgroundSvg from "@svg/HomeHeaderBackground";

const DemoRequestForm = () => {
  return (
    <Layout
      header={{ children: <Fragment />, color: "black" }}
      seo={{
        title: "Contact Us for Custom Work - Reactemplates",
        description:
          "Reach out to us for custom React templates and themes tailored to your needs. We're here to assist you!",
      }}
    >
      <StyledHomeHeader>
        <StyledHomeHeaderInner>
          <HeaderBackgroundIllustration>
            <HomeHeaderBackgroundSvg />
          </HeaderBackgroundIllustration>
          <StyledHomeHeaderMain>
            <div className="form-wrapper">
              <div className="form-container">
                <form
                  name="contact"
                  netlify
                  data-netlify="true"
                  method="POST"
                  data-netlify-honeypot="bot-field"
                >
                  <h1>Contact us</h1>
                  <p>
                    Have a project in mind? Fill out the form below,
                    and our team will get back to you to discuss your
                    custom requirements.
                  </p>

                  <div className="form-group">
                    <input type="hidden" name="bot-field" />
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      required
                    />
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <textarea
                      name="message"
                      placeholder="Message"
                      required
                    />
                  </div>

                  <button type="submit">Submit Your Request</button>
                </form>
              </div>
            </div>
          </StyledHomeHeaderMain>
        </StyledHomeHeaderInner>
        <StyledHomeHeaderLaptop>
          <StaticImage filename="home-laptop.png" alt="Logo" />
        </StyledHomeHeaderLaptop>
      </StyledHomeHeader>
    </Layout>
  );
};

export default DemoRequestForm;
